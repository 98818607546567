<template>
  <div class="product">
    <b-modal id="modal-info" title="取消換貨須知"  hide-footer centered :style="colorObject">
      <p>
        若訂購後有取消訂單/退貨需求，請登入會員於“我的訂單”點閱“訂單明細”。
        選點申請取消訂單/退貨，提交申請後，請等待店家確認。
        </p>
        <p>根據台灣消保法第十九條規範，享有收到商品後隔日起算七天猶豫期之權益(注意！猶豫期非試用期)商品退貨時必須回復原狀，亦即必須回復至您收到商品時的原始狀態
        (包含主機、附件、內外包裝、隨機文件、贈品等)，若為部分商品退貨則針對您已使用或保留之商品應另以市價計價，否則將影響您退貨的權限。
        提出申請後，經店家確認同意後，將會進行商品回收、退款/退刷等事項。</p>
        <p>
        請注意，依消費者保護法第19條及行政院消費者保護處公告「通訊交易解除權合理例外情事適用準則」規定，以下商品不適用猶豫期，以避免日後紛爭。        
      </p>
    </b-modal>

    <b-overlay
      :show="isAddCart"
      variant="dark"
      opacity="0.5"
      blur="5px"
      @click="isAddCart = false"
    >
      <b-carousel
        v-model="slide"
        :interval="4000"
        controls
        background="#ababab"
      >
        <b-carousel-slide
          v-for="i in 5"
          :key="i"
          :img-src="`https://picsum.photos/500?${i}`"
        ></b-carousel-slide>

        <div class="indicators position-absolute text-white rounded-pill">
          {{ slide + 1 }} / 5
        </div>
      </b-carousel>
      <div class="p-3 shadow-sm">
        <h1 class="product__title">Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡</h1>
        <div class="highlight p-1 px-2 mb-3">
          活動訊息HIGHLIGHT免運活動首購活動
        </div>
        <span class="price">NT$ 1,380</span>
        <span class="oprice ml-2">NT$ 1,980</span>
      </div>

      <div class="p-3 shadow-sm">
        <div class="h1">優惠活動</div>
        <ul class="text-muted">
          <li>
            免運優惠來囉！新會員首次下單百貨幫你付運費，最高可折運費 NT$ 200。
          </li>
          <li>多重優惠顯示，實際優惠折抵以購物車內的折抵金額為準。</li>
        </ul>
      </div>

      <div class="tab mt-3 shadow-sm">
        <nav class="nav justify-content-around">
          <a
            class="flex-fill text-sm-center nav-link h1 text-center"
            :class="nav == 'detail' && 'active'"
            @click="nav = 'detail'"
            role="button"
            >商品介紹</a
          >
          <a
            class="flex-fill text-sm-center nav-link h1 text-center"
            :class="nav == 'spec' && 'active'"
            @click="nav = 'spec'"
            role="button"
            >規格說明</a
          >
        </nav>

        <div class="p-3">
          <div v-if="nav == 'detail'">
            <h4 class="h1 mb-3">商品介紹</h4>
            <img
              v-for="i in 3"
              :src="`https://picsum.photos/500?${i}`"
              :key="i"
              class="img-fluid mb-2"
            />
            <ul>
              <li>▲抗 UV 400</li>
              <li>▲偏 光 鏡 片</li>
            </ul>
          </div>
          <div v-else-if="nav == 'spec'">
            <h4 class="h1 mb-3">規格說明</h4>

            <table class="table">
              <tbody>
                <tr>
                  <th class="text-muted">品牌</th>
                  <td>
                    <a href="#"
                      ><img
                        :src="`https://picsum.photos/50?${i}`"
                        class="img-fluid mr-2"
                      />
                      墨鏡人生
                    </a>
                  </td>
                </tr>
                <tr>
                  <th class="text-muted">規格</th>
                  <td>55-︎20-143</td>
                </tr>
                <tr>
                  <th class="text-muted">保存期限</th>
                  <td>10年</td>
                </tr>
                <tr>
                  <th class="text-muted">產地</th>
                  <td>臺灣</td>
                </tr>
                <tr>
                  <th class="text-muted">商品標籤</th>
                  <td>
                    <span class="badge badge-pill bg-secondary px-3 m-1 h1"
                      >女性</span
                    >
                    <span class="badge badge-pill bg-secondary px-3 m-1 h1"
                      >精品</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="p-3 shadow-sm">
        <div class="h1">購物須知</div>
        <a href="#" class="d-block" v-b-modal.modal-info>點我了解付款與運費</a>
        <a href="#" class="d-block" v-b-modal.modal-info>點我了解取消換貨須知</a>
        <div class="text-muted">
          付款後，從備貨到寄出商品為 3 個工作天。（不包含假日）
        </div>
      </div>
    </b-overlay>

    <footer class="fixed-bottom bg-white">
      <div v-if="isAddCart" class="row p-3 border-bottom">
        <div class="col-4">
          <div class="photo">
            <img
              :src="`https://picsum.photos/500?${i}`"
              class="img-fluid mr-2"
            />
          </div>
        </div>
        <div class="col-8">
          <div class="h1">Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡</div>
          <span class="price">NT$ 1,380</span>
          <span class="oprice ml-2">NT$ 1,980</span>
        </div>
      </div>
      <div class="p-3">
        <div class="row">
          <div class="col">
            <b-button
              v-if="!isAddCart"
              size="lg"
              variant="secondary"
              class="w-100"
              @click="isAddCart = true"
            >
              放入購物車
            </b-button>

            <div v-else>
              <b-form-spinbutton
                min="1"
                value="1"
                max="100"
                class="text-center px-0"
              ></b-form-spinbutton>
            </div>
          </div>
          <div class="col">
            <b-button size="lg" variant="primary" class="w-100" @click="ok()">
              直接購買
            </b-button>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import themeColor from "@/mixins/liff/themeColor";
export default {
  mixins: [themeColor],
  data() {
    return {
      slide: 0,
      nav: "detail",
      isAddCart: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.product {
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 80px;

  .indicators {
    z-index: 1;
    background: rgba($color: #90a4ae, $alpha: 0.7);
    left: 1rem;
    bottom: 1rem;
    font-size: 15px;
    font-weight: 400;
    padding: 5px 10px;
    line-height: 15px;
    font-family: "Helvetica";
  }

  ::v-deep .carousel-control-prev-icon {
    background-image: url(./images/icon-prev.svg);
  }
  ::v-deep .carousel-control-next-icon {
    background-image: url(./images/icon-next.svg);
  }

  h1,
  .h1 {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  }

  .highlight {
    font-size: 12px;
    background: #ffebee;
    border-radius: 4px;
  }

  .price {
    
    font-size: 20px;
    line-height: 23px;
    
  }

  .oprice {
    
    font-size: 17px;
    line-height: 20px;
    
  }

}

footer {
  .price {
    font-size: 16px !important;
  }

  .oprice {
    font-size: 14px !important;
  }

  .photo {
    margin-top: -30%;
    aspect-ratio: 1/1;
    img {
      border-radius: 8px;
      width: 100%;
    }
  }
}
</style>
